<div *ngIf="isMobile">
  <div class="container2">
      <div class="cancelButton">
        <a id="abortAddRentalUnitMobile" (click)="onSubmitCancel()"><fa-icon [icon]="faAbortCircle"></fa-icon></a>
      </div>
    <app-vertical-stepper></app-vertical-stepper>
  </div>
</div>

<!--
*
* Large Screens
*
-->

<div *ngIf="!isMobile">
  <mat-card>
    <mat-card-content>
      <div class="cancelButton">
        <a id="abortAddRentalUnitDesktop" (click)="onSubmitCancel()"><fa-icon [icon]="faAbortCircle"></fa-icon></a>
      </div>
      <div class="container2">
        <app-horizontal-stepper></app-horizontal-stepper>
      </div>
    </mat-card-content>
  </mat-card>
</div>
