import {Component, OnInit} from '@angular/core';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import {HomeComponent} from '../home/home.component';

@Component({
  selector: 'app-add-rental-unit',
  templateUrl: './add-rental-unit.component.html',
  styleUrls: ['./add-rental-unit.component.css']
})
export class AddRentalUnitComponent implements OnInit {
  faAbortCircle = faTimesCircle;

  constructor(private homeComponent: HomeComponent) {}

  isMobile = false;
  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 960;
    return w < breakpoint;
  }

  ngOnInit(): void {
    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
  }

  async onSubmitCancel(): Promise<void> {
    console.log('open /dashboard');
    await this.homeComponent.openContent('dashboard');
  }

}

@Component({
  selector: 'app-success-add-rentalunit-message-component',
  templateUrl: 'snackbarRentalUnitSuccess.html',
  styles: [`
    .container1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `],
})
export class SuccessAddRentalunitMessageComponent {
}

