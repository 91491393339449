import {Sensor} from './sensor.model';

export class Room {
  id: number;
  name: string;
  sensors = [];

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  async getRoom(): Promise<any> {
    return {
      id: this.id,
      name: this.name,
      sensors: this.sensors
    };
  }

  async addSensor(sensor): Promise<Sensor> {
    await this.sensors.push(sensor);
    return sensor;
  }


}
