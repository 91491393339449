<!-- on small Screens -->
<div class="withoutMatCards" fxHide.md fxHide.lg fxHide.xl>

  <div class="container1" fxHide.md fxHide.lg fxHide.xl>
    <h2>Infos</h2>
    <h2>Hello Mr. Mustermann</h2>
    <p>Here is a small overview of your settings and your house units.</p>
  </div>

  <div class="container2" fxHide.md fxHide.lg fxHide.xl>
    <h2>Add new Rental Unit</h2>
    <p>Here you can add new rental units.</p>
  </div>
  <div class="container3" fxHide.md fxHide.lg fxHide.xl>
    <button mat-raised-button name="addRentalUnitButtonSmallScreen" class="button-addRentalUser" type="submit" (click)="onSubmitAdd()">Add rental unit
    </button>
  </div>

  <div class="showRentalUnitContainer" fxHide.md fxHide.lg fxHide.xl>
    <h2>Overview rental units</h2>
    <app-overview-rental-units></app-overview-rental-units>
  </div>

</div>

<!-- on large Screens -->

<div class="withMatCards" fxHide.xs fxHide.sm>
  <div class="row1">

    <mat-card class="infoCard">
      <mat-card-content>
        <div class="container1">
          <h2>Infos</h2>
          <h2>Hello Mr. Mustermann</h2>
          <p>Here is a small overview of your settings and your house units.</p>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="welcomeCard">
      <mat-card-content>
        <div class="container2">
          <h2>Add new Rental Unit</h2>
          <p>Here you can add new rental units.</p>
        </div>
        <div class="container3">
          <button mat-raised-button name="addRentalUnitButtonLargeScreen" class="button-addRentalUser" type="submit" (click)="onSubmitAdd()">Add rental unit
          </button>
        </div>
      </mat-card-content>
    </mat-card>


  </div>
  <mat-card>
    <mat-card-content>
      <div class="showRentalUnitContainer">
        <h2>Overview rental units</h2>
        <app-overview-rental-units></app-overview-rental-units>
      </div>
    </mat-card-content>
  </mat-card>
</div>
