import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {RentalService} from '../services/rentalservice.service';
import {RentalUnit} from '../models/rentalUnit.model';
import {HomeComponent} from '../home/home.component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-overview-rental-units',
  templateUrl: './overview-rental-units.component.html',
  styleUrls: ['./overview-rental-units.component.css']
})
export class OverviewRentalUnitsComponent implements OnInit {
  objectKeys = Object.keys;
  rentalUnits: RentalUnit[] = [];
  subscription: Subscription;
  statusError: boolean;
  statusMessage: string;

  constructor(private route: ActivatedRoute, private rentalService: RentalService, private homeComponent: HomeComponent) {
  }

  ngOnInit(): void {
    this.subscription = this.rentalService.RentalUnitsSubject.subscribe(units => {
      this.statusError = false;
      if (units.length >= 1) {
        this.rentalUnits = [];
        for (const each of units) {
          this.generateRentalUnitStatus(each).then(response => this.rentalUnits.push(response)
          );
        }
      } else if (units.length === 1) {
        this.generateRentalUnitStatus(units[0]).then(response => this.rentalUnits.push(response));
      }
    });
  }

  async onSubmitUpdateRentalUnit(): Promise<void> {
    console.log('open /updateRentalUnit');
    await this.homeComponent.openContent('updateRentalUnit');
  }

  private async generateRentalUnitStatus(rentalUnit): Promise<RentalUnit> {
    let errors;
    errors = [];
    errors.push(await rentalUnit.generalInformation.validationError());
    errors.push(await rentalUnit.address.validationError());
    errors.push(await rentalUnit.checkSensorConfiguration());
    errors.push(await rentalUnit.checkRoomConfiguration());
    let countErros = 0;
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (errors[key] === true) {
          countErros++;
        }
      }
    }
    if (countErros >= 2) {
      this.statusError = true;
      this.statusMessage = 'Multiple Problems';
    } else if (countErros === 1) {
      this.statusError = true;
      if (errors[0] === true) {
        this.statusMessage = 'General configuration problem';
      }
      if (errors[1] === true) {
        this.statusMessage = 'Address configuration problem';
      }
      if (errors[2] === true) {
        this.statusMessage = 'Sensor configuration problem';
      }
      if (errors[3] === true) {
        this.statusMessage = 'Rooms configuration problem';
      }
    } else if (countErros === 0) {
      this.statusError = false;
      this.statusMessage = 'Status okay';
    }

    rentalUnit.error = this.statusError;
    rentalUnit.statusMessage = this.statusMessage;
    return rentalUnit;
  }
}
