<div class="tableContainer" >
  <div class="mat-elevation-z8">
    <div class="tableOptionsContainer">
      options
    </div>
    <table mat-table
           [dataSource]="dataSource" multiTemplateDataRows
           class="mat-elevation-z8">

      <!-- Normal Column  -->
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef> {{column}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="column !== 'state'">{{element[column]}}</div>
          <div *ngIf="column === 'state'">
            <span [ngClass]="element[column] ? 'colorRedDotsIconRentalUnit': 'colorGreenDotsRentalUnit'" class="dot"></span>

          </div>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="expandable-row-content"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <app-edit-rental-unit></app-edit-rental-unit>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row class="mat-header-rowCss" *matHeaderRowDef="columnsToDisplay" ></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
          class="tableElement-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
  </div>
</div>
