import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {RentalService} from './rentalservice.service';


@Injectable()
// @ts-ignore
export class RentalUnitResolver implements Resolve<any> {
  tmp;

  constructor(private rentalService: RentalService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    // load rentalUnits befor rendering Component
    this.tmp = this.rentalService.getAllRentalUnits();
    return this.tmp;
  }
}
