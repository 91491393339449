<mat-sidenav-container>
  <mat-sidenav #sidenav role="navigation">
    <app-nav-content>
    </app-nav-content>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <div fxHide.md fxHide.lg fxHide.xl>
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="navbarText">Smart Home</div>
      <div class="navItems" fxFlex fxLayout fxLayoutAlign="flex-end">
        <ul fxLayout fxLayoutGap="10px" class="navigation-items">
          <li fxHide.xs fxHide.sm>
            <div class="timeAndDate">
              <div class="time"><p>{{date   | date: "HH:mm"}}</p></div>
              <div class="date"><p>{{date   | date: "dd-MM-yyyy"}}</p></div>
            </div>
          </li>
          <li>
            <a class="navElements">
              <mat-icon class="material-icons-outlined" matBadge="2" matBadgeColor="warn" matBadgePosition="above after" matBadgeSize="small">notifications</mat-icon>
            </a>
          </li>
          <li>
            <a class="navElements" (click)="onSubmitLogout()">
              <fa-icon class="logoutButton" [icon]="faSignOut"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </mat-toolbar>
    <div class="mat-toolbar-container">
    </div>
    <main class="main">
      <!-- Content under 960px width -->
      <div class="mobileContent" fxHide.md fxHide.lg fxHide.xl>
        <app-dashboard *ngIf="dashboardSite"></app-dashboard>
        <app-add-rental-unit *ngIf="addRentalUnit"></app-add-rental-unit>
        <app-show-rental-units *ngIf="showRentalUnit"></app-show-rental-units>
      </div>
      <!-- Content over 960px width -->
      <div class="desktopContent" fxHide.xs fxHide.sm>
        <mat-drawer-container>
          <mat-drawer mode="side" opened>
            <app-nav-content>
            </app-nav-content>
          </mat-drawer>
          <mat-drawer-content>
            <app-dashboard *ngIf="dashboardSite"></app-dashboard>
            <app-add-rental-unit *ngIf="addRentalUnit"></app-add-rental-unit>
            <app-show-rental-units *ngIf="showRentalUnit"></app-show-rental-units>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
