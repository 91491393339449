import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {RentalUnit} from '../models/rentalUnit.model';
import {Subscription} from 'rxjs';
import {RentalService} from '../services/rentalservice.service';

export interface RentalUnitElement {
  id: number;
  houseUnit: string;
  rentalUnit: string;
  creationDate: string;
  state: boolean;
}

@Component({
  selector: 'app-show-rental-units',
  templateUrl: './show-rental-units.component.html',
  styleUrls: ['./show-rental-units.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ShowRentalUnitsComponent implements AfterViewInit, OnInit {

  ELEMENT_DATA: RentalUnitElement[] = [];
  dataSource;
  columnsToDisplay = ['id', 'houseUnit', 'rentalUnit', 'creationDate', 'state'];
  expandedElement: RentalUnitElement | null;
  rentalUnits: RentalUnit[] = [];
  subscription: Subscription;

  constructor(private rentalService: RentalService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.subscription = this.rentalService.RentalUnitsSubject.subscribe(async units => {
      if (units.length >= 1) {
        this.rentalUnits = [];
        this.ELEMENT_DATA = [];
        for (const each of units) {
          await this.ELEMENT_DATA.push(await this.mapRentalUnitToTable(each));
          await this.rentalUnits.push(each);
        }
      } else if (units.length === 1) {
        await this.ELEMENT_DATA.push(await this.mapRentalUnitToTable(units[0]));
        await this.rentalUnits.push(units[0]);
      }
      this.dataSource = new MatTableDataSource<RentalUnitElement>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit(): void {

  }

  private async mapRentalUnitToTable(rentalUnit): Promise<RentalUnitElement>{

    let errors;
    errors = [];
    errors.push(await rentalUnit.generalInformation.validationError());
    errors.push(await rentalUnit.address.validationError());
    errors.push(await rentalUnit.checkSensorConfiguration());
    errors.push(await rentalUnit.checkRoomConfiguration());

    let error = false;
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (errors[key] === true) {
          error = true;
        }
      }
    }
    const modal = {
      id: await rentalUnit.getRentalUnitObjectID(),
      houseUnit: 'Skyscraper‎1',                                                // Houseunit name hier hardcodiert!!!
      rentalUnit: await rentalUnit.getRentalUnitRentalUnitName(),
      creationDate: await rentalUnit.getRentalUnitCreationDate(),
      state: error
    } as RentalUnitElement;

    return modal;
  }

}
