import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './login/auth.guard';
import {RegisterComponent} from './register/register.component';
import {RentalUnitResolver} from './services/rental-unit-resolver.service';
import {IsSignedInGuard} from './login/isNotAuth.guard';

const routes: Routes = [
  {path: '', component: LoginComponent, canActivate: [IsSignedInGuard]},
  {path: 'register', component: RegisterComponent, canActivate: [IsSignedInGuard]},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard], resolve: {
      data: RentalUnitResolver
    }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, RentalUnitResolver, IsSignedInGuard]
})
export class AppRoutingModule {
}
