
export class Address {
  street: string;
  number: string;
  postalCode: string;
  city: string;

  constructor(street: string, numberAddress: string, postalCode: string, city: string) {
    this.street = street;
    this.number = numberAddress;
    this.postalCode = postalCode;
    this.city = city;
  }

  async getAddress(): Promise<object> {
    return {
      street: this.street,
      number: this.number,
      postalCode: this.postalCode,
      city: this.city,
    };
  }

  async validationError(): Promise<boolean> {
    if (this.street === '') {
      return true;
    } else if (this.number === '') {
      return true;
    } else if (this.postalCode === '') {
      return true;
    } else if (this.city === '') {
      return true;
    }
    return false;
  }

}

