import {Injectable} from '@angular/core';
import {API} from 'aws-amplify';
import {Auth} from 'aws-amplify';
import {Room} from '../models/room.model';
import {Sensor} from '../models/sensor.model';
import {RentalUnit} from '../models/rentalUnit.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RentalService {
  // @ts-ignore
  RentalUnitsSubject = new BehaviorSubject<RentalUnit[]>('');

  constructor() {
    console.log('init rental service');
    this.updateDataPeriodically();
  }

  async getAllRentalUnits(): Promise<Array<RentalUnit>> {
    console.log('addRentalUnitsService');
    const allRentalUnits = await this.serverGetAllRentalUnits();
    // console.log('ALL:' + JSON.stringify(allRentalUnits.Items));
    let rentalUnitsArray;
    rentalUnitsArray = [];
    for (const singleRentalUnit of allRentalUnits.Items) {
      let newRentalUnit;
      newRentalUnit = new RentalUnit(singleRentalUnit.objectID, singleRentalUnit.houseUnitID,
        singleRentalUnit.creationDate, singleRentalUnit.rentalUnitName);
      await newRentalUnit.setGeneralInformation(singleRentalUnit.generalInformation);
      await newRentalUnit.setAddress(singleRentalUnit.address);
      for (const singleRoom of singleRentalUnit.rooms) {
        let newRoom;
        newRoom = new Room(singleRoom.id, singleRoom.name);
        for (const singleSensor of singleRoom.sensors) {
          let newSensor;
          newSensor = new Sensor(singleSensor.id, singleSensor.name, singleSensor.type, singleSensor.description);
          await newRoom.addSensor(newSensor);
        }
        await newRentalUnit.addRoom(newRoom);
      }
      rentalUnitsArray.push(newRentalUnit);
    }
    console.log(rentalUnitsArray);
    this.RentalUnitsSubject.next(rentalUnitsArray);
    return rentalUnitsArray;
  }

  async addRentalUnit(rUName: string, rUStreet: string, rUNumber: string, rUPostalCode: string, rUCity: string, rUTypeOfHeating: string,
                      rUHeatingSystem: string, rUTypeOfWaterMeter: string, rUTypeOfElectricityMeter: string,
                      rUSizeOfRentalUnit: number, rUSolarSystem: boolean, rUFloor: number): Promise<boolean> {
    console.log('addRentalUnitService');
    let addNewRentalUnit;
    addNewRentalUnit = new RentalUnit('', '', '', rUName);
    await addNewRentalUnit.setAddress({
      street: rUStreet,
      number: rUNumber,
      postalCode: rUPostalCode,
      city: rUCity
    });
    await addNewRentalUnit.setGeneralInformation({
      typeOfHeating: rUTypeOfHeating,
      heatingSystem: rUHeatingSystem,
      typeOfWaterMeter: rUTypeOfWaterMeter,
      typeOfElectricityMeter: rUTypeOfElectricityMeter,
      sizeOfRentalUnit: rUSizeOfRentalUnit,
      solarSystem: rUSolarSystem,
      floor: rUFloor
    });
    // console.log(this.RentalUnitsSubject.getValue());
    const responseID = await this.serverAddRentalUnit(addNewRentalUnit);
    console.log(responseID);
    await addNewRentalUnit.setRentalUnitObjectID(responseID);
    this.RentalUnitsSubject.next(this.RentalUnitsSubject.getValue().concat([addNewRentalUnit]));
    return true;
  }


  private async serverGetAllRentalUnits(): Promise<any> {
    console.log('serverGetAllRentalUnits');

    // console.log((await Auth.currentSession()).getIdToken().getJwtToken());

    const apiName = 'SmartHomeApiGateway';
    const path = '/properties/rentalUnit';
    const myInit = { // OPTIONAL
      headers: {
        Accept: '*/*',
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }, // OPTIONAL
    };
    return API.get(apiName, path, myInit);
  }

  private async serverAddRentalUnit(rentalUnit): Promise<any> {
    console.log('serverAddRentalUnit');
    // tslint:disable-next-line:prefer-const
    let body = {
      rentalUnitName: await rentalUnit.getRentalUnitRentalUnitName(),
      houseUnitID: '',
      address: await rentalUnit.address.getAddress(),
      generalInformation: await rentalUnit.generalInformation.getGeneralInformation(),
      rooms: []
    };

    console.log(body);
    const apiName = 'SmartHomeApiGateway';
    const path = '/properties/rentalUnit';
    const myInit = { // OPTIONAL
      body, // replace this with attributes you need
      headers: {
        Accept: '*/*',
        Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      }, // OPTIONAL
    };
    return await API.post(apiName, path, myInit);
    // return true;
  }

  private updateDataPeriodically(): void {
    setInterval(() => {
      console.log('periodically get Rental Unit');
      this.getAllRentalUnits().then(r => console.log(r));
    }, 60000);
  }

  /* private createDate() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    return
  } */
}
