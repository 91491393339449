import {Address} from './address.model';
import {GeneralInformation} from './generalInformation.model';
import {Room} from './room.model';

export class RentalUnit {
  objectID: string;
  houseUnitID: string;
  creationDate: string;
  rentalUnitName: string;
  address: Address;
  generalInformation: GeneralInformation;
  rooms = [];
  roomError: boolean;
  sensorError: boolean;


  constructor(objectID: string, houseUnitID: string, creationDate: string, rentalUnitName: string) {
    this.objectID = objectID;
    this.houseUnitID = houseUnitID;
    this.creationDate = creationDate;
    this.rentalUnitName = rentalUnitName;
    this.sensorError = true;
    this.roomError = true;
  }

  async setGeneralInformation(generalInformation): Promise<void> {
    this.generalInformation = new GeneralInformation(generalInformation.typeOfHeating, generalInformation.heatingSystem,
      generalInformation.typeOfWaterMeter, generalInformation.typeOfElectricityMeter,
      generalInformation.sizeOfRentalUnit, generalInformation.solarSystem, generalInformation.floor);
  }

  async setAddress(address): Promise<void> {
    this.address = new Address(address.street, address.number, address.postalCode, address.city);
  }

  async addRoom(room): Promise<Room> {
    this.rooms.push(room);
    return room;
  }

  async checkRoomConfiguration(): Promise<boolean> {
    this.roomError = false;
    return false;
  }

  async checkSensorConfiguration(): Promise<boolean> {
    this.sensorError = false;
    return false;
  }

  async getRentalUnitObjectID(): Promise<string> {
    return this.objectID;
  }
  async getRentalUnitHouseUnitID(): Promise<string> {
    return this.houseUnitID;
  }
  async getRentalUnitCreationDate(): Promise<string> {
    return this.creationDate;
  }
  async getRentalUnitRentalUnitName(): Promise<string> {
    return this.rentalUnitName;
  }
  async setRentalUnitObjectID(id): Promise<void> {
    this.objectID = id;
  }
}
