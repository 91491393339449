<mat-horizontal-stepper [linear]="isLinear" #stepper>

  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>General</ng-template>

      <div class="checkboxesFirstFormGroup">

        <mat-form-field class="full-width-input">
          <input matInput name="rentalUnitName" placeholder="Name" formControlName="rentalUnitName" maxlength="15" required>
          <mat-hint align="end">{{firstFormGroup.get('rentalUnitName').value.length}} / 15</mat-hint>
        </mat-form-field>

        <mat-form-field class="full-width-input">
          <input matInput type="number" name="rentalUnitFloor" min="1" max="20" placeholder="Floor"
                 formControlName="rentalUnitFloor" required>
          <mat-hint align="end">{{firstFormGroup.get('rentalUnitFloor').value}} / 20</mat-hint>
        </mat-form-field>

        <div class="container5">
          <p>Rental unit m<sup style=" font-size: 10px">2</sup>: {{firstFormGroup.get('rentalUnitSize').value}}
          </p>
          <div class="container6">
            <fa-icon [icon]="faListUl"></fa-icon>
            <mat-slider name="rentalUnitSize" formControlName="rentalUnitSize" required
                        class="slider-selector"
                        [invert]="rentalUnitSizeSliderInvert"
                        [max]="rentalUnitSizeSliderMax"
                        [min]="rentalUnitSizeSliderMin"
                        [step]="rentalUnitSizeSliderStep"
                        [thumbLabel]="rentalUnitSizeSliderThumbLabel"
                        [vertical]="rentalUnitSizeSliderVertical">
            </mat-slider>
          </div>
        </div>

        <div class="rentalUnitNumbersRadioButtons">
          <p *ngIf="rentalUnitNumbersRadioButtons">Number of rooms:</p>
          <mat-radio-group *ngIf="rentalUnitNumbersRadioButtons" formGroupName="numberOfRooms"
                           aria-label="Select an option">
            <mat-radio-button value="1" [checked]="selectedFirstFormGroupRadioButtons === ''"
                              (change)="radioButtonGroupFirstFormValidator('one')">1
            </mat-radio-button>
            <mat-radio-button  value="2" (change)="radioButtonGroupFirstFormValidator('two')">2
            </mat-radio-button>
            <mat-radio-button value="3" (change)="radioButtonGroupFirstFormValidator('three')">3
            </mat-radio-button>
            <mat-radio-button value="4" (change)="radioButtonGroupFirstFormValidator('four')">4
            </mat-radio-button>
            <mat-radio-button value="5" (change)="radioButtonGroupFirstFormValidator('five')">5
            </mat-radio-button>
            <mat-radio-button value="other"
                              (change)="radioButtonGroupFirstFormValidator('other'); changeFormGroupRadioButton()">
              Other
            </mat-radio-button>
          </mat-radio-group>

          <mat-form-field *ngIf="!rentalUnitNumbersRadioButtons" class="full-width-input">
            <input matInput type="number" name="numberOfRooms" placeholder="Number of RoomsModel"
                   formControlName="numberOfRooms" min="1" max="10" required>
          </mat-form-field>
          <button *ngIf="!rentalUnitNumbersRadioButtons" name="clearBtn" mat-raised-button class="stepperBtn"
                  (click)="changeFormGroupRadioButton()">Back</button>
        </div>


      </div>

      <mat-error *ngIf="firstFormGroupError">
        {{firstFormGroupErrorMessage}}
      </mat-error>

      <div class="buttonGroupStepper">
        <button name="stepperBtnGeneralNext" mat-raised-button class="stepperBtn" matStepperNext
                (click)="onSubmitFirstFormGroup()">Next
        </button>
        <button name="stepperBtnGeneralClear" mat-raised-button class="stepperBtn" (click)="clearFields('firstSection')">
          Clear
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-template matStepLabel>Heating</ng-template>

      <div class="checkboxesSecondFormGroup">
        <mat-checkbox name="checkboxHeatingElement1" [ngClass]="{ 'colorRed' :secondFormGroupError }" class="checkbox1"
                      [checked]="selectedSecondFormGroupCheckBoxes === 'oilHeating'"
                      (change)="checkboxGroupSecondFormValidator('oilHeating')" formControlName="oilHeating">Oil
          heating
        </mat-checkbox>
        <mat-checkbox name="checkboxHeatingElement2" [ngClass]="{ 'colorRed' :secondFormGroupError }" class="checkbox2"
                      [checked]="selectedSecondFormGroupCheckBoxes === 'electricHeating'"
                      (change)="checkboxGroupSecondFormValidator('electricHeating')"
                      formControlName="electricHeating">
          Electronic heating
        </mat-checkbox>
        <mat-checkbox name="checkboxHeatingElement3" [ngClass]="{ 'colorRed' :secondFormGroupError }" class="checkbox3"
                      [checked]="selectedSecondFormGroupCheckBoxes === 'gasHeating'"
                      (change)="checkboxGroupSecondFormValidator('gasHeating')" formControlName="gasHeating">Gas
          heating
        </mat-checkbox>
        <mat-checkbox [ngClass]="{ 'colorRed' :secondFormGroupError }" class="checkbox3"
                      [checked]="selectedSecondFormGroupCheckBoxes === 'solarHeating'"
                      (change)="checkboxGroupSecondFormValidator('solarHeating')"
                      formControlName="solarHeating">
          Solarthermie
        </mat-checkbox>
        <mat-checkbox [ngClass]="{ 'colorRed' :secondFormGroupError }" class="checkbox4"
                      [checked]="selectedSecondFormGroupCheckBoxes === 'othersHeating'"
                      (change)="checkboxGroupSecondFormValidator('othersHeating')"
                      formControlName="othersHeating">Other
        </mat-checkbox>
      </div>
      <mat-error *ngIf="secondFormGroupError">
        {{secondFormGroupErrorMessage}}
      </mat-error>

      <div class="buttonGroupStepper">
        <button name="stepperBtnHeatingNext" mat-raised-button class="stepperBtn" matStepperNext
                (click)="onSubmitSecondFormGroup()">Next
        </button>
        <button name="stepperBtnHeatingClear" mat-raised-button class="stepperBtn" (click)="clearFields('secondSection')">
          Clear
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <ng-template matStepLabel>Electricity</ng-template>

      <div class="checkboxesThirdFormGroup">
        <mat-checkbox name="checkboxElectricityElement1" [ngClass]="{ 'colorRed' :thirdFormGroupError }" class="checkbox1"
                      [checked]="selectedThirdFormGroupCheckBoxes === 'digitalElectricity'"
                      (change)="checkboxGroupThirdFormValidator('digitalElectricity')"
                      formControlName="digitalElectricity">Digital electricity meter
        </mat-checkbox>
        <mat-checkbox name="checkboxElectricityElement2" [ngClass]="{ 'colorRed' :thirdFormGroupError }" class="checkbox2"
                      [checked]="selectedThirdFormGroupCheckBoxes === 'analogElectricity'"
                      (change)="checkboxGroupThirdFormValidator('analogElectricity')"
                      formControlName="analogElectricity">
          Analogue electricity meter
        </mat-checkbox>

        <mat-slide-toggle name="solarsystemToggle" (click)="onClickSolarSlideToggle()" formControlName="solarPower">
          Solarsystem: {{SolarPowerSlideCheckedStatus}}
        </mat-slide-toggle>


      </div>
      <mat-error *ngIf="thirdFormGroupError">
        {{thirdFormGroupErrorMessage}}
      </mat-error>

      <div class="buttonGroupStepper">
        <button name="stepperBtnElectricityNext" mat-raised-button class="stepperBtn" matStepperNext
                (click)="onSubmitThirdFormGroup()">Next
        </button>
        <button name="stepperBtnElectricityClear" mat-raised-button class="stepperBtn" (click)="clearFields('thirdSection')">
          Clear
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="fourthFormGroup">
    <form [formGroup]="fourthFormGroup">
      <ng-template matStepLabel>Water</ng-template>

      <div class="checkboxesThirdFormGroup">
        <mat-checkbox name="checkboxWaterElement1" [ngClass]="{ 'colorRed' :fourthFormGroupError }" class="checkbox1"
                      [checked]="selectedFourthFormGroupCheckBoxes === 'digitalWaterMeter'"
                      (change)="checkboxGroupFourthFormValidator('digitalWaterMeter')"
                      formControlName="digitalWaterMeter">Digital water meter
        </mat-checkbox>
        <mat-checkbox name="checkboxWaterElement2" [ngClass]="{ 'colorRed' :fourthFormGroupError }" class="checkbox2"
                      [checked]="selectedFourthFormGroupCheckBoxes === 'analogWaterMeter'"
                      (change)="checkboxGroupFourthFormValidator('analogWaterMeter')"
                      formControlName="analogWaterMeter">
          Analog water meter
        </mat-checkbox>
      </div>
      <mat-error *ngIf="fourthFormGroupError">
        {{fourthFormGroupErrorMessage}}
      </mat-error>

      <div class="buttonGroupStepper">
        <button name="stepperBtnWaterNext" mat-raised-button class="stepperBtn" matStepperNext
                (click)="onSubmitFourthFormGroup()">Next
        </button>
        <button name="stepperBtnWaterClear" mat-raised-button class="stepperBtn" (click)="clearFields('fourthSection')">
          Clear
        </button>
      </div>
    </form>
  </mat-step>


  <mat-step [stepControl]="fifthFormGroup">
    <form [formGroup]="fifthFormGroup">
      <ng-template matStepLabel>Heatingsystem</ng-template>

      <div class="checkboxesThirdFormGroup">
        <mat-checkbox name="checkboxHeatingsystemElement1" [ngClass]="{ 'colorRed' :fifthFormGroupError }" class="checkbox1"
                      [checked]="selectedFifthFormGroupCheckBoxes === 'underfloorHeating'"
                      (change)="checkboxGroupFifthFormValidator('underfloorHeating')"
                      formControlName="underfloorHeating">Floor heating
        </mat-checkbox>
        <mat-checkbox name="checkboxHeatingsystemElement2" [ngClass]="{ 'colorRed' :fifthFormGroupError }" class="checkbox2"
                      [checked]="selectedFifthFormGroupCheckBoxes === 'radiators'"
                      (change)="checkboxGroupFifthFormValidator('radiators')"
                      formControlName="radiators">
          Radiators
        </mat-checkbox>
        <mat-checkbox name="checkboxHeatingsystemElement3" [ngClass]="{ 'colorRed' :fifthFormGroupError }" class="checkbox2"
                      [checked]="selectedFifthFormGroupCheckBoxes === 'underfloorHeatingAndRadiators'"
                      (change)="checkboxGroupFifthFormValidator('underfloorHeatingAndRadiators')"
                      formControlName="underfloorHeatingAndRadiators">
          Floor heating and Radiators
        </mat-checkbox>
      </div>
      <mat-error *ngIf="fifthFormGroupError">
        {{fifthFormGroupErrorMessage}}
      </mat-error>

      <div class="buttonGroupStepper">
        <button name="stepperBtnHeatingsystemNext" mat-raised-button class="stepperBtn" matStepperNext
                (click)="onSubmitFifthFormGroup()">Next
        </button>
        <button name="stepperBtnHeatingsystemClear" mat-raised-button class="stepperBtn" (click)="clearFields('fifthSection')">
          Clear
        </button>
      </div>



    </form>
  </mat-step>


  <mat-step [stepControl]="sixthFormGroup">
    <form [formGroup]="sixthFormGroup">
      <ng-template matStepLabel name="Done">Done</ng-template>


      <div class="container9">
        <h3>Please check your entries: </h3>
        <div class="container10">
          <p><b>Rental unit name: </b>{{firstFormGroup.get('rentalUnitName').value}}</p>
          <p><b>Floor: </b>{{firstFormGroup.get('rentalUnitFloor').value}}</p>
          <p><b>Rental unit in m<sup style=" font-size: 10px">2</sup>:
          </b>{{firstFormGroup.get('rentalUnitSize').value}}</p>
          <p><b>Number of rooms: </b>{{firstFormGroup.get('numberOfRooms').value}}</p>
          <p><b>Heating: </b>{{secondFormGroup.get('heating').value}}</p>
          <p><b>Solarsystem: </b>{{SolarPowerSlideCheckedStatus}}</p>
          <p><b>Electricity meter: </b>{{thirdFormGroup.get('electricity').value}}</p>
          <p><b>Water meter: </b>{{fourthFormGroup.get('waterMeter').value}}</p>
          <p><b>heating system: </b>{{fifthFormGroup.get('heatingSystem').value}}</p>
        </div>
      </div>

      <div class="container11">
        <mat-checkbox name="checkboxDoneElement1" [ngClass]="{ 'colorRed' :sixthFormGroupError }" class="checkbox2"
                      [checked]="selectedSixthFormGroupCheckBoxes === true"
                      (change)="checkboxGroupSixthFormValidator(true)"
                      formControlName="inputCheckedAndAccept">
          Entries checked & AGB accepted
        </mat-checkbox>
      </div>

      <mat-error *ngIf="sixthFormGroupError">
        {{sixthFormGroupErrorMessage}}
      </mat-error>

      <div class="buttonGroupStepper">
        <button  name="submitAddRentalUnit" mat-raised-button class="stepperBtn" (click)="onSubmitAddRentalUnit()">Submit
        </button>
        <button  name="cancelAddRentalUnit"  mat-raised-button class="stepperBtn" (click)="onSubmitCancel()">Cancel</button>
      </div>

    </form>
  </mat-step>

</mat-horizontal-stepper>
