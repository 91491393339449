<div class="RentalUnitContainer">
  <div class="cardsContainer" *ngFor="let key of objectKeys(rentalUnits)">
    <mat-card>
      <mat-card-content>
        <div class="colorContainer" [ngClass]="rentalUnits[key].error ? 'colorRedRentalUnit': 'colorGreenRentalUnit'"></div>
        <div class="contentContainer">
          <div class="contentContainer1">
            <h2>{{rentalUnits[key].rentalUnitName}}</h2>
            <div class="contentContainer3">
              <div class="dateContainer">
                <p>Creation date</p>
                <div class="innerDateContainer">
                  <span class="material-icons">calendar_today</span>
                  <p>17/09/2020</p>
                </div>
              </div>
              <div class="additionalCostContainer">
                <p>Additional costs</p>
                <div class="innerAdditionalCostContainer">
                  <p>0.00 &euro;</p>
                </div>
              </div>
            </div>
          </div>
          <div class="contentContainer2">

            <div class="messageContainer">
              <span *ngIf="rentalUnits[key].error"
                    [ngClass]="rentalUnits[key].error ? 'colorRedIconRentalUnit': 'colorBlueIconRentalUnit'"
                    class="material-icons">error</span>
              <p *ngIf="rentalUnits[key].error">{{rentalUnits[key].statusMessage}}</p>
              <span *ngIf="!rentalUnits[key].error"
                    [ngClass]="!rentalUnits[key].error ? 'colorGreenIconRentalUnit': 'colorRedIconRentalUnit'"
                    class="material-icons">check_circle</span>
              <p *ngIf="!rentalUnits[key].error ">{{rentalUnits[key].statusMessage}}</p>
            </div>
            <div class="editContainer">
              <a (click)="onSubmitUpdateRentalUnit()"><span class="material-icons">play_circle_filled</span></a>
              <p>Edit</p>
            </div>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
