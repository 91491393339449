export class Sensor {
  id: number;
  name: string;
  type: string;
  description: string;

  constructor(id: number, name: string, type: string, description: string) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.description = description;
  }

  async getSensor(): Promise<object> {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      description: this.description
    };
  }


}
