export class GeneralInformation {
  typeOfHeating: string;
  heatingSystem: string;
  typeOfWaterMeter: string;
  typeOfElectricityMeter: string;
  sizeOfRentalUnit: number;
  solarSystem: boolean;
  floor: number;


  constructor(typeOfHeating: string, heatingSystem: string, typeOfWaterMeter: string, typeOfElectricityMeter: string,
              sizeOfRentalUnit: number, solarSystem: boolean, floor: number) {
    this.typeOfHeating = typeOfHeating;
    this.heatingSystem = heatingSystem;
    this.typeOfWaterMeter = typeOfWaterMeter;
    this.typeOfElectricityMeter = typeOfElectricityMeter;
    this.sizeOfRentalUnit = sizeOfRentalUnit;
    this.solarSystem = solarSystem;
    this.floor = floor;
  }

  async getGeneralInformation(): Promise<object> {
    return {
      typeOfHeating: this.typeOfHeating,
      heatingSystem: this.heatingSystem,
      typeOfWaterMeter: this.typeOfWaterMeter,
      typeOfElectricityMeter: this.typeOfElectricityMeter,
      sizeOfRentalUnit: this.sizeOfRentalUnit,
      solarSystem: this.solarSystem,
      floor: this.floor
    };
  }

  async validationError(): Promise<boolean> {
    if (this.typeOfHeating === '') {
      return true;
    } else if (this.heatingSystem === '') {
      return true;
    } else if (this.typeOfElectricityMeter === '') {
      return true;
    } else if (this.typeOfWaterMeter === '') {
      return true;
    }else if (this.sizeOfRentalUnit < 10) {
      return true;
    } else if (this.floor < 0 || this.floor > 20) {
      return true;
    }
    return false;
  }

}
