import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {faPowerOff} from '@fortawesome/free-solid-svg-icons';
import {faBell} from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  faSignOut = faPowerOff;
  faBell = faBell;
  dashboardSite: boolean;
  addRentalUnit: boolean;
  showRentalUnit: boolean;
  date: Date;

  constructor(private authService: AuthService) {
    this.dashboardSite = true;
    this.addRentalUnit = false;
    this.showRentalUnit = false;

    setInterval(() => {
      this.date = new Date();
    }, 10);
  }

  ngOnInit(): void {
  }

  onSubmitLogout(): void {
    console.log('logout');
    this.authService.logout();
  }

  async openContent(name): Promise<void> {
    if (name === 'dashboard') {
      this.addRentalUnit = false;
      this.dashboardSite = true;
      this.showRentalUnit = false;
    }
    if (name === 'addRentalUnit') {
      this.addRentalUnit = true;
      this.dashboardSite = false;
      this.showRentalUnit = false;
    }
    if (name === 'updateRentalUnit') {
      this.addRentalUnit = false;
      this.dashboardSite = false;
      this.showRentalUnit = true;
    }

  }

}
